<template>
  <div class="container">
    <!-- banner -->

     <img
      style="width: 100%"
      src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img-product-fuli.png"
      alt="banner_电商化采购"
    />
     <div class="fuli-title">
      <h1>平台介绍</h1>
     </div>
    <el-carousel :autoplay="true" height="810px">
        <el-carousel-item  v-for="page in 22" :key="page">
          <img
            :src="'https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/fuli/page_'+page+'.png'"
            alt="banner_电商化采购"
          />
        </el-carousel-item>
       
      </el-carousel>

  

    <div id="business-buy-desc">“ 一站式弹性福利解决方案 ”</div>

    <!-- 采购数字化 -->
    <section class="business-buy">
      <div>提升员工满意度 | 更多更省更贴心</div>
      <span>通过SaaS应用，建立企业采购标准化，数字化，为企业带来多重价值</span>

      <div class="business-buy-container display_flex justify-content_space-between">
        <div
          class="business-buy-item display_flex flex-direction_column justify-content_flex-center align-items_center"
        >
          <img
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/fuli/yangongfuli.png"
            alt="图片-员工福利"
          />
          <div>员工福利</div>
          <p>新年利事  端午粽香 月圆中秋  情满生辰。</p>
        </div>

        <div
          class="business-buy-item display_flex flex-direction_column justify-content_flex-center align-items_center"
        >
          <img
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/fuli/yuangongguanhuai.png"
            alt="图片-员工关怀"
          />
          <div>员工关怀</div>
          <p>
            春节礼包  亲子教育 家庭娱乐  母婴关爱
          </p>
        </div>

        <div
          class="business-buy-item display_flex flex-direction_column justify-content_flex-center align-items_center"
        >
          <img
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/fuli/yuangognchengzhagn.png"
            alt="图片-员工成长"
          />
          <div>员工成长</div>
          <p>定向旅游  拓展团建 兴趣活动  精英课堂。</p>
        </div>

        <div
          class="business-buy-item display_flex flex-direction_column justify-content_flex-center align-items_center"
        >
          <img
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/fuli/jiankang.png"
            alt="图片-员工健康"
          />
          <div>员工健康</div>
          <p>夏冬慰问  优选礼券,生活关怀  健身保健。</p>
        </div>

        <div
          class="business-buy-item display_flex flex-direction_column justify-content_flex-center align-items_center"
        >
          <img
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/fuli/zhihui.png"
            alt="图片-智慧运营"
          />
          <div>智慧运营</div>
          <p>提高企业员工满意度，提高供应商在线协同率，节省成本，提高效率。</p>
        </div>
      </div>
    </section>

    <!-- 实施服务保障 -->
    <section class="business-buy" style="padding: 0; background: #fff; height: 560px">
      <div>实施服务保障</div>
      <span>搭建售前、实施、售后各个任务模块团队，进行项目过程全服务</span>


      <div class='wrapper'>
          <div class='item ryze'></div>
          <div class='item irelia'></div>
          <div class='item jinx'></div>
          <div class='item katarina'></div>
      </div>
    </section>

    <!-- 底部栏 -->
    <footer-bar :border_hide="true" />
  </div>
</template>

<script>
import FooterBar from "../../components/FooterBar";

export default {
  name: "BusinessBuy",
  data() {
    return {
      isActive: true,
      swiperOptions: {
         mousewheel: true,
          // loop: true,
          speed: 1000,
          autoplay: {
            delay: 2000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          pagination: {
            el: '.swiper-pagination'
          },
          // Some Swiper option/callback...
        }
    };
  },
  components: {
    FooterBar,
  },
  methods: {
    changeItem(curItem) {
      this.curItem = curItem;
    },
  },
};
</script>

<style scoped lang="scss">
.fuli-title {
  margin-top: 60px;
  
  text-align: center;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 200;
  color: #16181d;
}
#business-buy-desc {
  height: 120px;
  background: linear-gradient(270deg, #870404 0%, #da1191 100%);
  text-align: center;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 120px;
}

.business-buy {
  text-align: center;
  padding-bottom: 28px;
  height: 506px;
  padding: 0 100px;
  background-image: url("https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_bg-buy.png");

  > div:nth-child(1) {
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #16181d;
    line-height: 45px;
    padding: 60px 0 8px 0;
  }

  > span {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6a7181;
    line-height: 30px;
    display: inline-block;
    margin-bottom: 44px;
  }
}

.business-buy-container {
  .business-buy-item {
    width: 18%;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #d60d28;
    padding: 28px 0 36px 0;

    img {
      width: 80px;
      height: 81px;
    }

    div {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #16181d;
      line-height: 28px;
      margin-top: 18px;
      margin-bottom: 20px;
    }

    p {
      padding: 0 12px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9ca7c2;
      line-height: 20px;

      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .business-buy-item:hover {
    cursor: pointer;
    box-shadow: 0px 20px 40px 0px rgba(29, 51, 107, 0.1);
    border: 1px solid #ff1ad9e4;
  }

  .business-buy-item.active {
    background: #ec95e3;
    color: #fff;
    box-shadow: 0px 20px 40px 0px rgba(29, 51, 107, 0.1);
  }
}

@media screen and (min-width: 1600px) {
  .business-buy {
    padding: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 400px;
}
.wrapper .item {
  display: flex;
  flex-grow: 1;
  transition: all 0.5s ease;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.wrapper .item:hover {
  flex-grow: 4;
  box-shadow: inset 0 0 20px #000;
}
.wrapper .item:after {
  content: "";
  width: 200%;
  height: 43.33%;
  background-color: rgba(0, 0, 0, 0.75);
  left: -50%;
  bottom: -10%;
  position: absolute;
  transform: rotate(-15deg);
  color: #fff;
  text-align: center;
  padding-top: 20px;
  text-transform: uppercase;
  font-size: 40px;
  font-family: "Raleway";
  font-weight: 100;
}
.wrapper .item.ryze {
  background-image: url("https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/fuli/dulibushu.jpg");
  background-position: 70% center;
}
.wrapper .item.ryze:after {
  content: "独立部署";
}
.wrapper .item.irelia {
  background-image: url("https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/fuli/jieru.jpg");
  background-position: 45% center;
}
.wrapper .item.irelia:after {
  content: "无缝接入";
}
.wrapper .item.jinx {
  background-image: url("https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/fuli/dingzhi.png");
  background-position: 70% center;
}
.wrapper .item.jinx:after {
  content: "定制服务";
}
.wrapper .item.katarina {
  background-image: url("https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/fuli/yunying.png");
  background-position: 70% center;
}
.wrapper .item.katarina:after {
  content: "独立运营";
}
.wrapper .item.ziggs {
  background-image: url("http://lolwp.com/wp-content/uploads/Ziggs_Splash_0.jpg");
  background-position: 75% center;
}
.wrapper .item.ziggs:after {
  content: "Ziggs";
}
</style>
